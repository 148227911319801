<template>
    <div class="emptyPage">
        <div class="emptyHeader"  @click="goOther">
            <img class="poster" :src="sysInfo.posterUrl" alt="">
        </div>
        <div class="emptyBody">
            <img class="emptyImg" src="../../assets/img/emptyImg.png" alt="">
            <div class="title">{{sysInfo.title}}</div>
            <div class="desc">{{sysInfo.content}}</div>
        </div>
     
    </div>
</template>
<script >

export default {
    props:{
        sysInfo:{
            type:Object
        },
    },
    data(){
        return {}
    },
    methods:{
        close(){
           location.href="about:blank"
        },
        goOther(){
            if(!!this.sysInfo.jumpUrl){
                window.open(this.sysInfo.jumpUrl)
            }
        }
    }
}
</script>
<style lang="less" scoped>
.emptyPage{
    width: 100vw;
    padding-bottom: 200px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    .emptyHeader{
        width: 100%;
        min-height: 123px;
        .poster{
            width: 100%;
            height: auto;
        }
    }
    .emptyBody{
       margin-top: 43px;
       display: flex;
       width: 100%;
       align-items: center;
       flex-direction: column; 
       padding: 0px 40px;
       box-sizing: border-box;
       text-align: center;
       .emptyImg{
           width: 142px;
            height: 142px;
            font-weight: bold;
            cursor: pointer;
       }
       .title{
            padding:0px 34px;
           margin-top: 24px;
           font-size: 16px;
       }
       .desc{
        padding:0px 24px;
        margin-top: 15px;
        color: #666666;
       }
    }
  
}
</style>